@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

body {
  background-color: #F7F7F7;
  font-family: 'Raleway' !important;
  font-size: 16px;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.container-fluid {
  padding-left: 35px;
  padding-right: 35px;
}

a.navbar-brand {
  font-size: 1em;
  line-height: 19px;
  background-color: white;
  border-radius: 30px;
  padding: 10px 25px;
}

.nav-link.btn {
  min-width: 165px;
  border-radius: 30px;
  padding: 10px !important;
  font-weight: 700;
  background-color: white;
  color: #4299E1 !important;
  border: 1px solid #4299E1;
}

.nav-link.btn>i {
  background-color: #4299E1;
  color: white;
  padding: 5px;
  font-size: 0.875em;
  border-radius: 50%;
}

.nav-item:not(.account-control) .nav-link.btn:hover,
.nav-item .nav-link.btn.active {
  color: white !important;
  background-color: #4299E1;
}

.nav-link.btn:hover>i,
.nav-link.btn.active>i {
  color: #4299E1;
  background-color: white;
}

.account-control {
  border: none;
  border-radius: 30px;
}

.account-control>.nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 243px;
}

.drop-link {
  text-decoration: none;
  color: #0000005C;
  font-weight: 400;
  font-size: 1em;
}

.notif-highlight {
  padding: 5px;
  background-color: #EB5757;
  border-radius: 50%;
  position: absolute;
  margin-left: -8px;
  margin-top: 0px;
}

.header-link {
  text-decoration: none;
  color: #000000CC;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.show>.dropdown-menu {
  display: block;
  position: absolute;
}

.modal {
  border-radius: 16px;
  font-size: 14px;

}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
}

.btn-primary {
  background-color: #4299E1;
  border-color: #4299E1;
  color: #FFFFFF;
}

.btn-outline-primary {
  background-color: transparent;
  border-color: #4299E1;
  color: #4299E1;
}

.btn-secondary {
  background-color: #9f9f9f;
  border-color: #9f9f9f;
  color: #FFFFFF;
}

.btn-outline-secondary {
  background-color: transparent;
  border-color: #9f9f9f;
  color: #9f9f9f;
}

.text-primary {
  color: #4299E1 !important;
}

.nav-tabs {
  border-bottom: 4px solid #4299E1 !important;
}

.nav-link {
  color: #0000007A;
}

.nav-item:not(.account-control) .nav-link:hover,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #FFFFFF;
  background-color: #4299E1;
  border-color: #4299E1;
}

.chart2-container div.col-*:nth-last-child(1) {
  border-left: 1px solid #0000007A;
}

.form-switch .form-check-input {
  height: 1.25rem;
  width: 2.5rem;
}

input:focus,
select:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

@media (min-width: 1200px) {
  .col-xl-20 {
    flex: 0 0 auto;
    width: 20%;
  }
}