@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  background-color: #F7F7F7;
  font-family: 'Raleway' !important;
  font-size: 16px;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.container-fluid {
  padding-left: 35px;
  padding-right: 35px;
}

a.navbar-brand {
  font-size: 1em;
  line-height: 19px;
  background-color: white;
  border-radius: 30px;
  padding: 10px 25px;
}

.nav-link.btn {
  min-width: 165px;
  border-radius: 30px;
  padding: 10px !important;
  font-weight: 700;
  background-color: white;
  color: #4299E1 !important;
  border: 1px solid #4299E1;
}

.nav-link.btn>i {
  background-color: #4299E1;
  color: white;
  padding: 5px;
  font-size: 0.875em;
  border-radius: 50%;
}

.nav-item:not(.account-control) .nav-link.btn:hover,
.nav-item .nav-link.btn.active {
  color: white !important;
  background-color: #4299E1;
}

.nav-link.btn:hover>i,
.nav-link.btn.active>i {
  color: #4299E1;
  background-color: white;
}

.account-control {
  border: none;
  border-radius: 30px;
}

.account-control>.nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 243px;
}

.drop-link {
  text-decoration: none;
  color: #0000005C;
  font-weight: 400;
  font-size: 1em;
}

.notif-highlight {
  padding: 5px;
  background-color: #EB5757;
  border-radius: 50%;
  position: absolute;
  margin-left: -8px;
  margin-top: 0px;
}

.header-link {
  text-decoration: none;
  color: #000000CC;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.show>.dropdown-menu {
  display: block;
  position: absolute;
}

.modal {
  border-radius: 16px;
  font-size: 14px;

}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
}

.btn-primary {
  background-color: #4299E1;
  border-color: #4299E1;
  color: #FFFFFF;
}

.btn-outline-primary {
  background-color: transparent;
  border-color: #4299E1;
  color: #4299E1;
}

.btn-secondary {
  background-color: #9f9f9f;
  border-color: #9f9f9f;
  color: #FFFFFF;
}

.btn-outline-secondary {
  background-color: transparent;
  border-color: #9f9f9f;
  color: #9f9f9f;
}

.text-primary {
  color: #4299E1 !important;
}

.nav-tabs {
  border-bottom: 4px solid #4299E1 !important;
}

.nav-link {
  color: #0000007A;
}

.nav-item:not(.account-control) .nav-link:hover,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #FFFFFF;
  background-color: #4299E1;
  border-color: #4299E1;
}

.chart2-container div.col-*:nth-last-child(1) {
  border-left: 1px solid #0000007A;
}

.form-switch .form-check-input {
  height: 1.25rem;
  width: 2.5rem;
}

input:focus,
select:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

@media (min-width: 1200px) {
  .col-xl-20 {
    flex: 0 0 auto;
    width: 20%;
  }
}
#dashboard .header-container {
  margin-bottom: 30px;
}
#dashboard .header-text {
  font-weight: 700;
  font-size: 1.5em;
  line-height: 29px;
  color: #4299E1;
}
#dashboard .card {
  border-radius: 8px;
  box-shadow: 0px 6px 18px -5px rgba(195, 212, 221, 0.3), 0px 6px 18px -5px rgba(195, 212, 221, 0.04);
}
#dashboard .card-container {
  border: none;
  border-radius: 20px;
  box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.2);
  margin-bottom: 65px;
}
#dashboard .card-container > .card-body {
  padding: 35px !important;
}
#dashboard .card-container:not(.card-container-header) > div > div > div > .card > .card-body {
  background: linear-gradient(180deg, #FFFFFF 63.77%, #F2F2F2 100%);
  border-radius: 30px 30px 0 0;
}
#dashboard .dropdown-toggle {
  color: rgba(0, 0, 0, 0.6392156863);
}
#dashboard .dropdown-toggle::after {
  display: none;
}
#dashboard .title-text {
  font-size: 0.875em;
  font-weight: 400;
}
#dashboard .input-group .form-control,
#dashboard .input-group .form-select {
  border-radius: 30px 0 0 30px;
  background-color: white;
  border: none;
  padding: 10px 25px;
}
#dashboard .input-group .input-group-text {
  border-radius: 0 30px 30px 0;
  background-color: white;
  border: none;
  padding: 10px 25px;
}
#dashboard .dropdown-menu-start[data-bs-popper],
#dashboard .dropdown-menu-start {
  right: auto;
  left: -150%;
}
#dashboard .accordion-item {
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
}
#dashboard .section-container {
  cursor: pointer;
  width: 100%;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  border: 3px dashed rgba(0, 0, 0, 0.24);
  align-items: center;
  margin-bottom: 15px;
  border-radius: 18px;
  color: rgba(0, 0, 0, 0.4784313725);
}
#dashboard .header-section {
  font-weight: 400;
  font-size: 1.125em;
  line-height: 22px;
  color: #4299E1 !important;
}
#dashboard .loader-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=dashboard.css.map */
#login .card,
#login .btn {
  border-radius: 0.75rem;
}
#login .card {
  max-width: 500px;
  width: 100%;
}
#login .card-body {
  padding: 3rem;
}
#login label {
  font-weight: 500;
}
#login h1 {
  color: #555;
  font-weight: 500;
  font-size: 1em !important;
  margin-bottom: 1.75rem !important;
}
#login .form-control {
  border-radius: 0 1rem 1rem 0 !important;
  padding: 0.5rem 1rem;
}
#login .input-group-text {
  border-radius: 1rem 0 0 1rem;
  padding: 0.75rem 1rem;
  background-color: white;
  border-right: none !important;
  color: grey;
}
#login .forgot-pwd {
  text-decoration: none;
  font-size: 0.875em;
  color: #1F2937;
  transition: all 0.2s ease;
}
#login .btn-primary {
  background-color: #4299E1 !important;
  border-color: #4299E1 !important;
}
#login input:focus {
  outline: none !important;
  box-shadow: none !important;
}
@media (max-width: 767px) {
  #login .card-body {
    padding: 1.5rem;
  }
}/*# sourceMappingURL=login.css.map */
#List .card {
  border-radius: 8px;
  box-shadow: 0px 6px 18px -5px rgba(195, 212, 221, 0.3), 0px 6px 18px -5px rgba(195, 212, 221, 0.04);
}
#List .header-container {
  margin-bottom: 30px;
}
#List .header-text {
  font-weight: 700;
  font-size: 1.5em;
  line-height: 29px;
  color: #4299E1;
}
#List .card {
  border-radius: 8px;
  box-shadow: 0px 6px 18px -5px rgba(195, 212, 221, 0.3), 0px 6px 18px -5px rgba(195, 212, 221, 0.04);
}
#List .card-container {
  border: none;
  border-radius: 20px;
  box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.2);
  margin-bottom: 65px;
}
#List .card-container > .card-body {
  padding: 35px !important;
}
#List .card-container-header > div > div > div > .card {
  height: 105px;
}
#List .card-container:not(.card-container-header) > div > div > div > .card > .card-body {
  background: linear-gradient(180deg, #FFFFFF 63.77%, #F2F2F2 100%);
  border-radius: 30px 30px 0 0;
}
#List .dropdown-toggle {
  color: rgba(0, 0, 0, 0.6392156863);
}
#List .dropdown-toggle::after {
  display: none;
}
#List .title-text {
  font-size: 0.875em;
  font-weight: 400;
}
#List .input-group .form-control,
#List .input-group .form-select {
  border-radius: 30px 0 0 30px;
  background-color: white;
  border-right: none;
  padding: 10px 25px;
}
#List .input-group .input-group-text {
  border-radius: 0 30px 30px 0;
  background-color: white;
  border-left: none;
  padding: 10px 25px;
}
#List .dropdown-menu-start[data-bs-popper],
#List .dropdown-menu-start {
  right: auto;
  left: -150%;
}
#List .accordion-item {
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
}
#List .section-container {
  cursor: pointer;
  width: 100%;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  border: 3px dashed rgba(0, 0, 0, 0.24);
  align-items: center;
  margin-bottom: 15px;
  border-radius: 18px;
  color: rgba(0, 0, 0, 0.4784313725);
}
#List .header-section {
  font-weight: 400;
  font-size: 1.125em;
  line-height: 22px;
  color: #4299E1 !important;
}
#List .loader-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=list.css.map */
#templates .header-container {
  margin-bottom: 30px;
}
#templates .header-text {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #4299E1;
}
#templates .card {
  border-radius: 8px;
  box-shadow: 0px 6px 18px -5px rgba(195, 212, 221, 0.3), 0px 6px 18px -5px rgba(195, 212, 221, 0.04);
}
#templates .card-container {
  border: none;
  border-radius: 20px;
  box-shadow: 0px 20px 60px rgba(128, 144, 155, 0.2);
  margin-bottom: 65px;
}
#templates .card-container > .card-body {
  padding: 35px !important;
}
#templates .card-container-header > div > div > div > .card {
  height: 105px;
}
#templates .card-container:not(.card-container-header) > div > div > div > .card > .card-body {
  background: linear-gradient(180deg, #FFFFFF 63.77%, #F2F2F2 100%);
  border-radius: 30px 30px 0 0;
}
#templates .dropdown-toggle {
  color: rgba(0, 0, 0, 0.6392156863);
}
#templates .dropdown-toggle::after {
  display: none;
}
#templates .title-text {
  font-size: 14px;
  font-weight: 400;
}
#templates .input-group:not(.template-name) .form-control,
#templates .input-group:not(.template-name) .form-select {
  border-radius: 30px 0 0 30px;
  background-color: white;
  border: none;
  padding: 10px 25px;
}
#templates .input-group:not(.template-name) .input-group-text {
  border-radius: 0 30px 30px 0;
  background-color: white;
  border: none;
  padding: 10px 25px;
}
#templates .input-group.template-name ::-webkit-input-placeholder {
  color: silver;
  font-style: italic;
  opacity: 1;
}
#templates .input-group.template-name ::placeholder {
  color: silver;
  font-style: italic;
  opacity: 1;
}
#templates .input-group.template-name :-ms-input-placeholder {
  color: silver;
  font-style: italic;
}
#templates .input-group.template-name ::-ms-input-placeholder {
  color: silver;
  font-style: italic;
}
#templates .input-group.template-name .form-control:read-only {
  font-size: 1.25rem;
  color: #4299E1;
  background-color: white;
  border: none;
  padding: 5px 10px;
}
#templates .input-group.template-name .form-control {
  color: #4299E1;
  font-size: 1.25rem;
}
#templates .input-group.template-name .input-group-text {
  background-color: white;
  border: none;
  padding: 5px 10px;
}
#templates .dropdown-menu-start[data-bs-popper],
#templates .dropdown-menu-start {
  right: auto;
  left: -150%;
}
#templates .accordion-item {
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
}
#templates .section-container {
  cursor: pointer;
  width: 100%;
  padding: 24px 16px;
  display: flex;
  justify-content: center;
  border: 3px dashed rgba(0, 0, 0, 0.24);
  align-items: center;
  margin-bottom: 15px;
  border-radius: 18px;
  color: rgba(0, 0, 0, 0.4784313725);
}
#templates .header-section {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #4299E1 !important;
}
#templates .loader-container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
#templates .badge .bg-danger {
  background-color: rgba(244, 60, 60, 0.08) !important !important;
  color: #F43C3C !important !important;
}
#templates .recharts-wrapper {
  width: inherit !important;
}
#templates .recharts-wrapper .recharts-surface {
  width: inherit !important;
}
#templates .rdt_TableHead {
  color: #4E73F8;
}
#templates .rdt_TableHead .rdt_TableHeadRow {
  background-color: rgba(78, 115, 248, 0.0784313725) !important;
}
#templates .widgets {
  border: none;
  box-shadow: none;
}
#templates .widgets .card-body {
  padding: 0px;
}
#templates .widgets .card-body .i-widget-container {
  padding: 12px 0 12px 12px;
}
#templates .widgets .card-body .i-widget-container span {
  border-radius: 16px;
  padding: 10px 13px;
  font-size: 25px;
}
#templates .widgets .card-body .s-widget-container p {
  margin-bottom: 2px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3607843137);
}
#templates .widgets .card-body .s-widget-container span {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
#templates .customize {
  background-color: white !important;
  color: #4299E1 !important;
  border: none;
  font-size: 14px;
  font-weight: 700;
}
#templates .customize:focus {
  box-shadow: none;
  outline: none;
}
#templates .rdt_TableRow .rdt_TableCell {
  border: 1px solid #B9B9B9 !important;
}
#templates .toast-container {
  position: fixed !important;
  z-index: 1055;
}/*# sourceMappingURL=templates.css.map */
